import React from 'react';
import { CarouselProvider } from 'pure-react-carousel';

import { SlidesWidget } from './slides-widget';

export const SliderWidget = ({ items, ...rest }) => {
  return (
    <div className="" {...rest}>
      <CarouselProvider
        naturalSlideWidth={3000}
        naturalSlideHeight={700}
        visibleSlides={1}
        step={1}
        isPlaying
        totalSlides={items.length}
        interval={5000}
      >
        <SlidesWidget items={items} />
      </CarouselProvider>
    </div>
  );
};
