import { gql } from '@apollo/client';

export const HOME_PAGE_DATA = gql`
  query homePageData($itemsFilter: ItemsFilterInput, $brandsFilter: BrandsFilterInput) {
    itemsTopSelling(filter: $itemsFilter) {
      nodes {
        uid
        sku
        name
        slug
        description
        supplier {
          uid
        }
        brand {
          uid
          name
          slug
        }
        categories {
          uid
          name
          slug
        }
        primaryImage {
          url
          thumbUrl
          mediumUrl
        }
        images {
          url
        }
        prices {
          type
          currencyCode
          currencySign
          value
        }
        availability {
          availabilityControl
          availableQty
          outOfStockThreshold
          lowInStockThreshold
        }
      }
    }
    brandsTopSelling(filter: $brandsFilter) {
      nodes {
        uid
        name
        slug
        coverImage {
          url
          mediumUrl
          thumbUrl
        }
      }
    }
    brandsFeatured(filter: $brandsFilter) {
      nodes {
        uid
        name
        slug
        coverImage {
          url
          mediumUrl
          thumbUrl
        }
      }
    }
    itemsFeatured(filter: $itemsFilter) {
      nodes {
        uid
        sku
        name
        slug
        description
        supplier {
          uid
        }
        brand {
          uid
          name
          slug
        }
        categories {
          uid
          name
          slug
        }
        primaryImage {
          url
          thumbUrl
          mediumUrl
        }
        images {
          url
        }
        prices {
          type
          currencyCode
          currencySign
          value
        }
        availability {
          availabilityControl
          availableQty
          outOfStockThreshold
          lowInStockThreshold
        }
      }
    }
    promotionBanners {
      nodes {
        uid
        name
        placement
        clickUrl
        bannerImage {
          url
        }
      }
    }
  }
`;
