import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { AttentionSimpleIcon } from '../icons/Icons';
import { UpdateLocationButton } from '../update-location-button';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    backgroundColor: 'var(--dark-gray)',
    margin: 0,
    padding: '20px 0',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    color: 'var(--white)',
    lineHeight: '14px',
    fontWeight: '400',
    margin: 0,
    paddingRight: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  iconWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px 0 0',
    color: theme.palette.primary.main,
    '& svg': {
      width: '17px',
      height: '17px',
    },
  },
}));

const label =
  'Attention! Let us know your zip code so we can display all the products available in your area';

export const AttentionBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container className={classes.container}>
        <p className={classes.description}>
          <span className={classes.iconWrapper}>
            <AttentionSimpleIcon />
          </span>
          {label}
        </p>

        <UpdateLocationButton isAttentionBar={true} />
      </Container>
    </div>
  );
};
