import { gql } from '@apollo/client';

export const CONFIG_QUERY = gql`
  query currentConfig {
    current {
      config {
        seller {
          contact {
            formalName
            phone
            email
          }
        }
        storefront {
          general {
            name
            shopUrl
            primaryColorHex
            secondaryColorHex
            logoImage {
              url
            }
            longLogoImage {
              url
            }
          }
          checkout {
            allowedCountries
            allowedLanguage
          }
          helpCenter {
            url
            chatbotId
          }
          wholesale {
            enabled
          }
        }
      }
    }
  }
`;
