import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollTop = () => {
  const { pathname } = useLocation(); // Get the current path

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever the pathname changes
  }, [pathname]); // Run the effect when the pathname changes

  return null;
};
