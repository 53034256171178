import React, { useState } from 'react';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  Link,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { InfoIcon } from '../icons/Icons';
import { Spinner } from '../spinner';
import { Button } from '../button';
import { displayCentsAsDollars } from '../../utils';
import { CouponCodeForm } from '../coupon-code-form';
import { OUTER_LINKS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    width: 'auto',
    padding: '0',
    color: 'var(--dark-gray)',
    fontSize: '14px',
    fontWeight: 'bold',
    '& span': {
      fontSize: '12px',
      fontWeight: 'normal',
    },
    '& .MuiListItemText-primary': {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      columnGap: '16px',
      '& span': {
        fontSize: '14px',
      },
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    width: 'auto',
    padding: '0',
    [theme.breakpoints.up('sm')]: {
      rowGap: '20px',
    },
  },
  displayOnMobile: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  displayOnDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  wrapped: {
    padding: '0 5px',
  },
  onRight: {
    marginLeft: 'auto',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  grayBold: {
    color: 'var(--gray)',
    fontWeight: 'bold',
  },
  fullWidth: {
    width: '100%',
  },
  mainBox: {
    top: '10px',
    position: 'sticky',
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      border: 'solid',
      borderColor: 'var(--light-gray)',
      boxShadow: '0px 2px 20px #1B1B1D26',
    },
  },
  accordionBox: {
    margin: '0 !important',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
    },
  },
  accordionHeader: {
    padding: '30px 24px',
    height: '24px',
    minHeight: '24px !important',
    maxHeight: '24px !important',
    backgroundColor: 'var(--light-gray)',
  },
  accordionDetails: {
    padding: '16px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '24px 24px 0 24px',
    },
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: '3px',
    width: '100%',
    padding: '5px 0 0 0',
    color: 'var(--dark-gray)',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  infoIcon: {
    fill: theme.palette.primary.main,
    width: '0.9em',
    height: '0.9em',
  },
  totalBox: {
    padding: '24px 22px',
  },
  totalSection: {
    fontSize: '22px',
    color: 'var(--dark-gray)',
  },
  totalButton: {
    width: '100%',
    maxWidth: '100%',
  },
  spinner: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '& > div.MuiCircularProgress-root': {
      width: '22px !important',
      height: '22px !important',
    },
  },
  image: {
    width: '80px',
    height: '80px',
    minWidth: '80px',
    border: 'solid',
    borderColor: 'var(--light-gray)',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '65px',
      height: '65px',
      minWidth: '65px',
    },
  },
}));

export const ShoppingCartTotals = ({
  summaryData,
  summaryIsLoading,
  isLoading,
  areErrorsPresent,
  onClick,
  idButton,
  onApplyCouponCode,
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(true);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
  };

  return (
    <Box className={classes.mainBox} position="sticky">
      {summaryIsLoading && <Spinner className={classes.spinner} />}

      <Accordion expanded={expand} className={classes.accordionBox}>
        <AccordionSummary className={clsx(classes.accordionHeader, classes.flexRow)}>
          <div>Order Details</div>
          <span className={classes.onRight}>
            <Link onClick={toggleAccordion} className={classes.primary}>
              {expand ? 'Hide' : 'Show'}
            </Link>
          </span>
        </AccordionSummary>
        <AccordionDetails className={clsx(classes.accordionDetails, classes.flexColumn)}>
          {summaryData?.details?.map((shippingData, index) => (
            <div key={index} className={classes.flexColumn}>
              <ListItem className={classes.flexRow}>
                <div className={classes.uppercase}>Shipper #{index + 1}</div>
                <div className={classes.onRight}>
                  <span className={classes.wrapped}>Shipping:</span>
                  {displayCentsAsDollars(shippingData?.shippingPrice)}
                </div>
              </ListItem>
              {shippingData.cartItems.map((cartItem, index) => (
                <ListItem key={index} className={classes.flexRow}>
                  <div className={classes.image}>
                    <img src={cartItem?.item?.image} alt={cartItem?.item?.name} />
                  </div>
                  <ListItemText primary={cartItem?.item?.name} secondary={cartItem?.item?.brand} />
                  <div className={classes.grayBold}>x{cartItem?.qty}</div>
                  <div>{displayCentsAsDollars(cartItem?.currentPrice)}</div>
                </ListItem>
              ))}
              {index !== summaryData.details.length - 1 && <Divider />}
            </div>
          ))}
        </AccordionDetails>
      </Accordion>

      <Box className={clsx(classes.flexColumn, classes.totalBox)}>
        {/* same block as a separate element for the desktop */}
        <div>
          <div className={classes.flexColumn}>
            <div className={clsx(classes.flexRow, classes.fullWidth)}>
              <div className={classes.grayBold}>
                Subtotal ({pluralize('items', summaryData?.itemsCount, true)}):
              </div>
              <div className={classes.onRight}>{displayCentsAsDollars(summaryData?.subtotal)}</div>
            </div>

            <div className={clsx(classes.flexRow, classes.fullWidth)}>
              <div className={classes.grayBold}>
                Shipping ({pluralize('items', summaryData?.shippingsCount, true)}):
              </div>
              <div className={classes.onRight}>
                {displayCentsAsDollars(summaryData?.totalShipping)}
              </div>
            </div>

            {summaryData?.discountPercentage > 0 && (
              <div className={clsx(classes.flexRow, classes.fullWidth)}>
                <div className={classes.grayBold}>
                  Discount ({summaryData.discountPercentage} %):
                </div>
                <div className={classes.onRight}>
                  -{displayCentsAsDollars(summaryData?.totalDiscount)}
                </div>
              </div>
            )}

            <Divider />
            <CouponCodeForm onApplyCouponCode={onApplyCouponCode} />
            <Divider />
            <div className={classes.infoBox}>
              <InfoIcon className={classes.infoIcon} />
              <div className="text-left">Sales Tax Will Be Calculated During Payment Process</div>
            </div>
          </div>
        </div>

        <div className={clsx(classes.flexRow, classes.totalSection, classes.uppercase)}>
          <div>Total:</div>
          <div className={clsx(classes.onRight, classes.primary)}>
            {displayCentsAsDollars(summaryData?.total)}
          </div>
        </div>

        <div>
          <Button
            id={idButton}
            className={classes.totalButton}
            variant="contained"
            disabled={areErrorsPresent}
            onClick={() => !isLoading && onClick()}
          >
            Proceed to payment
          </Button>
        </div>
        <div className="text-left text-xxs">
          <span className="text-red-600">*</span> By proceeding you agree to our{' '}
          <Link target="__blank" href={OUTER_LINKS.privacy_policy_url}>
            <span className="uppercase underline"> salonhq privacy policy</span>
          </Link>{' '}
          and{' '}
          <Link target="__blank" href={OUTER_LINKS.term_of_service_url}>
            <span className="uppercase underline"> SalonHQ Messaging Terms of Service </span>
          </Link>
        </div>
      </Box>
    </Box>
  );
};
