import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLocalStoreFrontSlug } from '../../utils';
import { Button } from '../button';
import { ROUTING_CONFIG } from '../../constants';

export const RequestReturnButton = ({ disabled }) => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const goToRequestReturnPage = () => {
    const url = ROUTING_CONFIG.orderRequestReturn
      .replace(':storeFrontSlug', storeFrontSlug)
      .replace(':orderId', orderId);
    navigate(url);
  };

  return (
    <Button color="primary" variant="contained" onClick={goToRequestReturnPage} disabled={disabled}>
      Request Return
    </Button>
  );
};
