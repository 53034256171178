import { PRICE_TYPES } from '../constants';
import { getPriceByType } from './get-price-by-type';

export const getProductPrices = (productData) => {
  const retail = getPriceByType(productData?.prices, PRICE_TYPES.retail);
  const sale = getPriceByType(productData?.prices, PRICE_TYPES.sale);
  const wholesale = getPriceByType(productData?.prices, PRICE_TYPES.wholesale);

  return {
    retail: retail?.value ?? null,
    sale: sale?.value ?? null,
    wholesale: wholesale?.value ?? null,
  };
};
