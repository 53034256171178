import React, { useContext, useMemo } from 'react';
import { AppBarCategories } from './app-bar-categories';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { UpdateLocationButton } from '../update-location-button';
import { Auth } from '../auth';
import { HeaderLogo } from '../main-layout/header-logo';
import { SearchPanel } from '../search-panel';
import { IconsBlock } from './icons-block';
import { Context } from '../../store';

export const Desktop = ({ navRoutes }) => {
  const { state } = useContext(Context);
  const { account } = state || {};

  const renderNavLinks = useMemo(
    () =>
      navRoutes.map((route) => (
        <NavLink
          key={route.label}
          to={route.url}
          end={route.label === 'home'}
          target={route.target}
          className={({ isActive }) =>
            clsx(
              'my-auto mr-4 p-2 text-xs font-semibold uppercase',
              isActive ? 'text-white' : 'text-gray-light',
            )
          }
        >
          {route.label}
        </NavLink>
      )),
    [navRoutes],
  );

  return (
    <div className="hidden shadow-lg lg:block">
      <div className="flex h-10 w-full items-center justify-between bg-secondary px-14">
        <div className="flex">{renderNavLinks}</div>
        <div className="flex gap-3">
          {!account && <UpdateLocationButton />}
          <Auth />
        </div>
      </div>
      <div className="grid h-16 grid-cols-12 gap-x-4 px-14 align-middle">
        <HeaderLogo />
        <SearchPanel />
        <IconsBlock />
      </div>
      <div className="mx-14 border-t">
        <AppBarCategories />
      </div>
    </div>
  );
};
