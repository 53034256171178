import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { CartEntity } from '../cart-entity';
import { LeftArrowIcon } from '../icons/Icons';

const selectSlides = (isMediumResolution, isSmallResolution) => {
  if (isMediumResolution) {
    return {
      visibleSlides: 6,
    };
  }
  if (isSmallResolution) {
    return {
      visibleSlides: 4,
    };
  }
  return {
    visibleSlides: 2,
  };
};

export const BrandsCarouselWidget = ({ title, items, type, classNameEntity, ...rest }) => {
  const isMediumResolution = useMediaQuery('@media(min-width: 760px)');
  const isSmallResolution = useMediaQuery('@media(min-width: 450px)');

  return (
    <div {...rest} className="mb-10">
      <h2 className="mb-9 text-lg font-bold text-gray-dark">{title}</h2>
      <CarouselProvider
        className="relative px-5"
        naturalSlideWidth={200}
        naturalSlideHeight={200}
        {...selectSlides(isMediumResolution, isSmallResolution)}
        step={1}
        totalSlides={items.length}
      >
        <Slider className="px-0 outline-none">
          {items.map((item, index) => (
            <Slide className="outline-none" key={item.uid} index={index}>
              <CartEntity className={clsx(classNameEntity)} data={item} type={type} small />
            </Slide>
          ))}
        </Slider>
        <ButtonBack className="absolute -left-4 top-1/2 -translate-y-1/2 text-gray-light outline-none">
          <LeftArrowIcon className="h-6 w-6 lg:h-7 lg:w-7" />
        </ButtonBack>
        <ButtonNext className="absolute -right-4 top-1/2 -translate-y-1/2 rotate-180 text-gray-light outline-none">
          <LeftArrowIcon className="h-6 w-6 lg:h-7 lg:w-7" />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};
