import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { BRAND_PAGE_DATA } from '../queries';
import { useFilteredProducts } from './useFilteredProducts';
import { clientNameService } from '../services';

export const useBrand = ({ brandSlug, zipCode, stateCode }) => {
  const {
    data: { brands: { nodes: brands = [] } = {} } = {},
    loading,
    error,
  } = useQuery(BRAND_PAGE_DATA, {
    variables: {
      ...(zipCode && !clientNameService.isWholesaleClient
        ? {
            filter: {
              uidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName],
              slugEq: brandSlug,
            },
          }
        : {
            filter: {
              slugEq: brandSlug,
            },
          }),
    },
    context: {
      clientName: clientNameService.clientName,
    },
  });

  const {
    products,
    hasMore,
    loading: productsLoading,
    error: productsError,
    fetchListMore,
  } = useFilteredProducts({
    brandSlugEq: brandSlug,
    ...(zipCode && !clientNameService.isWholesaleClient
      ? {
          uidOmitsItemRestrictionsIn: [zipCode, stateCode?.shortName],
          brandUidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName],
        }
      : {}),
  });

  const brand = useMemo(
    () => (brands ?? []).find(({ slug }) => slug === brandSlug),
    [brandSlug, brands],
  );

  if (error) {
    console.error(error);
  }
  if (productsError) {
    console.error(productsError);
  }

  return {
    products,
    hasMore,
    brand,
    isLoading: loading || productsLoading,
    isError: error || productsError,
    fetchListMore,
  };
};
