import React from 'react';
import { BurgerIcon, CrossIcon } from '../icons/Icons';
import { HeaderLogo } from '../main-layout/header-logo';
import { IconsBlock } from './icons-block';

export const MobileHeader = ({ toggleMenu, isMenuOpen }) => {
  return (
    <div className="px-4">
      <div className="flex h-16 items-center justify-between">
        <div className="flex w-11/12 items-center">
          <div
            className="mr-4 flex h-7 w-7 min-w-7 items-center justify-center rounded-full bg-primary"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <CrossIcon /> : <BurgerIcon />}
          </div>
          <HeaderLogo />
        </div>

        <IconsBlock onClick={toggleMenu} isMenuOpen={isMenuOpen} />
      </div>
    </div>
  );
};
