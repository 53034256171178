import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useErrors } from './useErrors';
import { getStoreFrontUrl } from '../utils';

export const CREATE_ORDER_ACCOUNT = gql`
  mutation createOrderAccount(
    $landingUrl: String!
    $contactInput: ContactInput!
    $configInput: AccountConfigInput
    $addressInput: AddressInput
  ) {
    createOrderAccount(
      landingUrl: $landingUrl
      contactInput: $contactInput
      configInput: $configInput
      addressInput: $addressInput
    ) {
      identifier
      uid
      addresses {
        uid
      }
    }
  }
`;

export const useCreateOrderAccount = () => {
  const [createOrderAccountMutation] = useMutation(CREATE_ORDER_ACCOUNT);
  const { errorsHandler } = useErrors();
  const [loading, setLoading] = useState(false);

  const createOrderAccount = async ({
    formalName,
    email,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
    phone,
    orderNotificationsType,
  }) => {
    console.log(
      formalName,
      email,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
      phone,
      orderNotificationsType,
    );
    try {
      setLoading(true);
      const correctPhone = phone?.replace(/[\s()_]/g, '');

      const response = await createOrderAccountMutation({
        variables: {
          landingUrl: getStoreFrontUrl(window),
          contactInput: {
            formalName,
            email,
            phone: correctPhone?.length > 2 ? correctPhone : null,
          },
          configInput: {
            orderNotificationsType,
          },
          addressInput: {
            type: 'shipping',
            streetLine1: addressLine1,
            streetLine2: addressLine2,
            city,
            stateCode: state,
            postalCode: zipCode,
            countryCode: country,
          },
        },
      });

      setLoading(false);

      return response?.data?.createOrderAccount;
    } catch (error) {
      errorsHandler(error);
      setLoading(false);
      throw new Error();
    }
  };

  return {
    loading,
    createOrderAccount,
  };
};
