import React, { useContext, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Context } from '../store';
import { useCategory } from '../hooks';
import { ROUTING_CONFIG } from '../constants';
import { clientNameService, sessionCacheStorage } from '../services';
import { InfiniteScrollGrid, ProductsItem } from '../components';
import {
  getLocalStoreFrontSlug,
  getStateByZipCode,
  getCategoryPageForGoogleAnalytics,
} from '../utils';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '25px',
    lineHeight: '28px',
    color: 'var(--dark-gray)',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2),
    overflowWrap: 'break-word',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: '29px',
      lineHeight: '29px',
    },
  },
  subTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    color: 'var(--dark-gray)',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
  },
}));

export const CategoryPage = () => {
  const { state } = useContext(Context);
  const { categorySlug } = useParams();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const navigate = useNavigate();
  const classes = useStyles();
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { relatedCategories, products, category, isLoading, isError, hasMore, fetchListMore } =
    useCategory({
      filter: {
        categoriesSlugEq: categorySlug,
        ...(zipCode && !clientNameService.isWholesaleClient
          ? {
              uidOmitsItemRestrictionsIn: [zipCode, stateCode?.shortName],
              brandUidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName],
            }
          : {}),
      },
      categorySlug,
    });

  const categoryName = category?.name ?? '';

  useEffect(() => {
    if (category && !isLoading && !isError) {
      getCategoryPageForGoogleAnalytics(window, categoryName, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  if (!isLoading && !category) {
    navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug), {
      replace: true,
    });
  }

  const title = () => (
    <Grid container spacing={1}>
      <Grid container xs={12} md={9} alignItems="center">
        <Grid item xs={12}>
          <h1 className={classes.title}>{categoryName}</h1>
          <div className="flex flex-wrap">
            {relatedCategories?.map((category, index) => (
              <Link key={category.uid} to={`/${storeFrontSlug}/categories/${category.slug}`}>
                {category.name} {index !== relatedCategories.length - 1 && <>&bull;</>}
                <>&nbsp;</>
              </Link>
            ))}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
  const subTitle = () => <h2 className={classes.subTitle}>All Products</h2>;

  return (
    <InfiniteScrollGrid
      itemComponent={ProductsItem}
      customTitle={title()}
      title={categoryName}
      subTitle={subTitle()}
      data={products}
      isLoading={isLoading}
      isError={isError}
      fetchListMore={fetchListMore}
      hasMore={hasMore}
    />
  );
};
