import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.gray.light}`,
    '&:hover': {
      background: 'rgba(232,232,232,0.3)',
    },
  },
}));

export const MobileListItem = ({ data, onRowClick, children, ...props }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  if (!data) return null;

  return (
    <Box
      className={classes.item}
      onClick={(e) => {
        onRowClick?.({ e, navigate, data, ...props });
      }}
    >
      {children}
    </Box>
  );
};
