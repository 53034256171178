import { useQuery } from '@apollo/client';
import { useErrors } from './useErrors';
import { AVAILABILITY_ITEMS } from '../queries';
import { getStateByZipCode } from '../utils';
import { clientNameService, zipCodeService } from '../services';

export const useAvailabilityItems = () => {
  const zipCode = zipCodeService.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { errorsHandler } = useErrors();
  const { refetch } = useQuery(AVAILABILITY_ITEMS, {
    skip: true,
    context: {
      clientName: clientNameService.clientName,
    },
  });

  const getItemsAvailability = async (itemsIds) => {
    try {
      const response = await refetch({
        filter: {
          uidIn: itemsIds,
          ...(zipCode && !clientNameService.isWholesaleClient
            ? {
                uidOmitsItemRestrictionsIn: [zipCode, stateCode?.shortName],
                brandUidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName],
              }
            : {}),
        },
      });

      const items = response?.data?.items?.nodes;

      if (items) {
        return items;
      }
    } catch (error) {
      errorsHandler(error);
    }
  };

  return {
    getItemsAvailability,
  };
};
