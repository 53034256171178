import { useMutation } from '@apollo/client';
import { useErrors } from './useErrors';
import { CREATE_ORDER, CREATE_ORDER_WHOLESALE } from '../mutations/createOrderMutation';
import { clientNameService } from '../services';

export const useCreateOrder = () => {
  const [createOrderMutation] = useMutation(
    clientNameService.isWholesaleClient ? CREATE_ORDER_WHOLESALE : CREATE_ORDER,
  );
  const { errorsHandler } = useErrors();

  const createOrder = async ({ buyerUid, orderedItems, shippingAddressUid, couponCodeUid }) => {
    try {
      const response = await createOrderMutation({
        variables: {
          buyerUid,
          orderedItems,
          shippingAddressUid,
          couponCodeUid,
        },
        context: {
          clientName: clientNameService.clientName,
        },
      });

      return response?.data?.createOrder ?? null;
    } catch (error) {
      errorsHandler(error);
      return null;
    }
  };

  return {
    createOrder,
  };
};
