import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import {
  getCheckoutPageForGoogleAnalytics,
  getFormattedOrderItems,
  getItemsIds,
  getLocalStorageBasket,
  getLocalStoreFrontSlug,
} from '../../utils';
import {
  useAvailabilityItems,
  useComparingItemsByAvailability,
  useCreateCheckout,
  useCreateOrder,
  useCreateOrderAccount,
  useErrors,
  useShoppingCartSummary,
  useUpdateAccount,
} from '../../hooks';
import { Context } from '../../store';
import { storageService } from '../../services';
import { ACTIONS_TYPES, LOCAL_STORAGE_KEYS } from '../../constants';
import { ShippingForm } from '../shipping-form';
import { ShoppingCartTotals } from '../shopping-cart-totals';
import { Spinner } from '../spinner';
import { useVerifyCouponCode } from '../../hooks/use-verify-coupon-code';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useStyles = makeStyles((theme) => ({
  checkboxText: {
    color: theme.palette.gray.main,
    fontSize: '14px',
  },
  privacyLink: {
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  spinner: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '& > div.MuiCircularProgress-root': {
      width: '22px !important',
      height: '22px !important',
    },
  },
  divider: {
    marginTop: '5px',
    marginBottom: '10px',
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    wordBreak: 'break-word',
  },
}));

export const ShoppingCartShipping = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useContext(Context);
  const { errorsHandler } = useErrors();
  const { createOrderAccount } = useCreateOrderAccount();
  const { updateAccount } = useUpdateAccount();
  const { createOrder } = useCreateOrder();
  const { createCheckout } = useCreateCheckout();
  const { getItemsAvailability } = useAvailabilityItems();
  const { comparingItemsByAvailability } = useComparingItemsByAvailability();
  const formRef = useRef();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const [isErrorValidation, setErrorValidation] = useState(true);
  const { runVerifyCouponCodeQuery, couponCodeUid } = useVerifyCouponCode();

  const { summaryData, loading: summaryIsLoading } = useShoppingCartSummary(
    state?.cart,
    couponCodeUid,
  );

  const handleSubmit = () => {
    if (formRef?.current) {
      formRef.current.handleSubmit();
    }

    setTimeout(() => window.scrollTo({ top: 100, left: 100, behavior: 'smooth' }), 0);
  };

  const proceedPayment = async (values) => {
    try {
      setIsLoading(true);

      const basket = await getLocalStorageBasket();

      const shoppingCartItemsIds = await getItemsIds(basket, 'uid');
      const availabilityItems = await getItemsAvailability(shoppingCartItemsIds);
      const { isError, newCart } = await comparingItemsByAvailability(availabilityItems, basket);
      const orderItems = getFormattedOrderItems(basket);

      if (isError) {
        storageService.setItem(LOCAL_STORAGE_KEYS.isError, true);
        dispatch({ type: ACTIONS_TYPES.UPDATE_LOCAL_CART, payload: newCart });
        return navigate(`/${storeFrontSlug}/cart`);
      }

      const stripe = await stripePromise;
      let orderAccountData;
      if (state?.account) {
        orderAccountData = await updateAccount({
          firstName: values.firstName,
          lastName: values.lastName,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          country: values.country,
          phone: values.phone,
          orderNotificationsType: values.orderNotificationsType,
          needShowSuccess: false,
        });
      } else {
        orderAccountData = await createOrderAccount({
          formalName: `${values?.firstName?.trim?.()} ${values?.lastName?.trim?.()}`,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          country: values.country,
          phone: values.phone,
          orderNotificationsType: values.orderNotificationsType,
          email: values.email,
        });
      }

      const createOrderData = await createOrder({
        buyerUid: orderAccountData.uid,
        orderedItems: orderItems,
        shippingAddressUid: orderAccountData.addresses[0].uid,
        couponCodeUid,
      });

      const createCheckoutData = await createCheckout({
        orderUid: createOrderData.uid,
        backLinks: {
          successBackUrl: `${window.origin}/${storeFrontSlug}/order-successful`,
          cancelBackUrl: `${window.origin}/${storeFrontSlug}/order-canceled`,
        },
      });

      await stripe.redirectToCheckout({
        sessionId: createCheckoutData?.remoteSession?.id,
      });

      setIsLoading(false);
    } catch (error) {
      errorsHandler(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state.cart && !isLoading) {
      getCheckoutPageForGoogleAnalytics(window, !!state?.account);
    }
  }, [isLoading]);

  const onApplyCouponCode = (code) => {
    runVerifyCouponCodeQuery(code);
  };

  return (
    <Grid container spacing={4}>
      {isLoading && <Spinner className={classes.spinner} />}

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <ShippingForm
          formRef={formRef}
          account={state?.account}
          onSubmit={proceedPayment}
          checkError={setErrorValidation}
        />
      </Grid>

      {/* idButton prop need to Google Analytics, task CEP-2317 and CEP-2393 */}
      {state?.cart?.length && (
        <Grid align="right" item xs={12} sm={12} md={6} lg={6}>
          <ShoppingCartTotals
            summaryData={summaryData}
            summaryIsLoading={summaryIsLoading}
            onClick={handleSubmit}
            isLoading={isLoading}
            areErrorsPresent={!isErrorValidation}
            idButton="proceed-to-payment"
            onApplyCouponCode={onApplyCouponCode}
          />
        </Grid>
      )}
    </Grid>
  );
};
