import { gql } from '@apollo/client';

export const CREATE_ADDRESS = gql`
  mutation createAddress($accountUid: String!, $input: AddressInput!) {
    createAddress(accountUid: $accountUid, input: $input) {
      uid
    }
  }
`;

export const CREATE_ADDRESS_WHOLESALE = gql`
  mutation createAddress($input: AddressInput!) {
    createAddress(input: $input) {
      uid
    }
  }
`;
