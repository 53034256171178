import { getProductPrices, getProductImage, getCategoryName } from '../utils';
import { IMAGE_SIZE_VALUES } from '../constants';
import { clientNameService } from '../services';

export const useCartItems = (cart) => {
  const cartItems = cart.map((item) => {
    const price = getProductPrices(item.data);

    return {
      uid: item.uid,
      image: getProductImage(item.data, IMAGE_SIZE_VALUES.small),
      name: item.data.name,
      category: getCategoryName(item.data),
      price: clientNameService.isWholesaleClient ? price.wholesale : price.sale || price.retail,
      amount: item.amount,
      availability: item?.data?.availability,
    };
  });

  const totalAmount = cartItems.reduce((acc, item) => acc + item.amount, 0);
  const totalSum = cartItems.reduce((acc, item) => item.amount * item.price + acc, 0);

  return {
    cartItems,
    totalAmount,
    totalSum,
  };
};
