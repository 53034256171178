import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { setPlaceholderImageOnError } from '../../utils';
import { BlueDotIcon, GrayDotIcon, LeftArrowIcon } from '../../components/icons/Icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      maxWidth: '400px',
    },
  },
  imagesListContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    maxHeight: '400px',
    cursor: 'pointer',
    overflowY: 'scroll',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    '& > img': {
      width: '80px',
      height: '80px',
      objectFit: 'contain',
    },
  },
  dotListContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  mainImageContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: '400px',
    maxHeight: '400px',
  },
  mainImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  selected: {
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  arrowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '35px',
    height: '35px',
    background: '',
    padding: 0,
    border: 'none',
    lineHeight: 0,
    position: 'absolute',
    top: '45%',
    outline: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      opacity: 0.7,
    },
  },
  back: {
    left: '10px',
  },
  next: {
    right: '10px',
    transform: 'rotateZ(180deg)',
  },
  icon: {
    width: '20px',
    height: '20px',
    color: 'var(--white)',
    [theme.breakpoints.up('sm')]: {},
  },
}));

export const ProductImages = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const classes = useStyles();

  const isMediumResolution = useMediaQuery('@media(min-width: 960px)');
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const leftArrowClickHandler = () => {
    if (selectedImageIndex === 0) {
      setSelectedImageIndex(images.length - 1);
      return;
    }

    setSelectedImageIndex((currentIndex) => currentIndex - 1);
  };

  const rightArrowClickHandler = () => {
    if (selectedImageIndex === images.length - 1) {
      setSelectedImageIndex(0);
      return;
    }
    setSelectedImageIndex((currentIndex) => currentIndex + 1);
  };

  return (
    <div className={classes.imagesContainer}>
      {isMediumResolution && (
        <div className={classes.imagesListContainer}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image?.url}
              alt="logo"
              onClick={() => handleImageClick(index)}
              className={selectedImageIndex === index ? classes.selected : ''}
            />
          ))}
        </div>
      )}
      {!isMediumResolution && (
        <div className={classes.dotListContainer}>
          {images.map((_, index) => (
            <div key={index} onClick={() => handleImageClick(index)}>
              {selectedImageIndex === index ? <BlueDotIcon /> : <GrayDotIcon />}
            </div>
          ))}
        </div>
      )}
      <div className={classes.mainImageContainer}>
        <img
          className={classes.mainImage}
          src={images[selectedImageIndex]?.url || ''}
          alt={images[selectedImageIndex]?.name}
          onError={setPlaceholderImageOnError}
        />
        {images.length > 1 && (
          <>
            <button
              type="button"
              onClick={leftArrowClickHandler}
              className={clsx(classes.arrowButton, classes.back, 'bg-gray-main opacity-80')}
            >
              <LeftArrowIcon className={classes.icon} />
            </button>
            <button
              type="button"
              onClick={rightArrowClickHandler}
              className={clsx(classes.arrowButton, classes.next, 'bg-gray-main opacity-80')}
            >
              <LeftArrowIcon className={classes.icon} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};
