import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ORDERS_DETAILS } from '../queries';
import { clientNameService } from '../services';

export const useOrderDetails = ({ orderId }) => {
  const { loading, error, data } = useQuery(ORDERS_DETAILS, {
    variables: { orderId },
    context: {
      clientName: clientNameService.clientName,
    },
    fetchPolicy: 'no-cache',
  });

  const order = useMemo(() => {
    if (data?.orders?.nodes?.length) {
      return data?.orders?.nodes[0];
    }

    return null;
  }, [data]);

  const paymentDetails = useMemo(() => {
    if (order) {
      return data.orderPayments;
    }

    return null;
  }, [data]);

  return {
    loading,
    error,
    order,
    paymentDetails,
  };
};
