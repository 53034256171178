import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../store';
import { clientNameService, sessionCacheStorage } from '../services';
import { useQueryString, useSearchProducts } from '../hooks';
import { InfiniteScrollGrid, ProductsItem } from '../components';
import { getStateByZipCode, getProductSearchPageForGoogleAnalytics } from '../utils';

export const SearchPage = () => {
  const { state } = useContext(Context);
  const { search } = useLocation();
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const params = useQueryString(search);
  const [searchQuery, setSearchQuery] = useState(params?.query);
  const { products, isLoading, isError, fetchListMore, fetchList, hasMore } = useSearchProducts({
    query: params?.query,
    ...(zipCode && !clientNameService.isWholesaleClient
      ? { filter: { excludedPostalCode: zipCode, excludedStateCode: stateCode?.shortName } }
      : {}),
  });

  const handleSearch = () => {
    setSearchQuery(params?.query);
    fetchList();
  };

  useEffect(() => {
    if (searchQuery !== params?.query) {
      handleSearch();
    }
  }, [params?.query]);

  useEffect(() => {
    if (!isLoading && !isError) {
      getProductSearchPageForGoogleAnalytics(
        window,
        params?.query,
        products.length,
        sessionCacheStorage.hasToken,
      );
    }
  }, [isLoading]);

  return (
    <InfiniteScrollGrid
      itemComponent={ProductsItem}
      title="Search"
      data={products}
      isLoading={isLoading}
      isError={isError}
      fetchListMore={fetchListMore}
      hasMore={hasMore}
    />
  );
};
