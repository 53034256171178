import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderCanceledImg from '../assets/canceled-order.svg';
import { OrderStatusLayout } from '../components';
import { getLocalStoreFrontSlug, getOrderCanceledPageForGoogleAnalytics } from '../utils';

export const OrderCanceledPage = () => {
  const navigate = useNavigate();
  const storeFrontSlug = getLocalStoreFrontSlug();

  useEffect(() => {
    getOrderCanceledPageForGoogleAnalytics(window);
  }, []);

  return (
    <OrderStatusLayout
      title="Order Canceled"
      subTitle="You’ve canceled the order"
      image={OrderCanceledImg}
      imageAlt="Order Canceled"
      buttonText="Try again"
      onClickButton={() => navigate(`/${storeFrontSlug}/cart`)}
    />
  );
};
