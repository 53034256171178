import { useMutation } from '@apollo/client';
import { useErrors } from './useErrors';
import { CREATE_CHECKOUT } from '../mutations/createCheckoutMutation';
import { clientNameService } from '../services';

export const useCreateCheckout = () => {
  const [createCheckoutMutation] = useMutation(CREATE_CHECKOUT);
  const { errorsHandler } = useErrors();

  const createCheckout = async ({ orderUid, backLinks }) => {
    try {
      const response = await createCheckoutMutation({
        variables: {
          orderUid,
          backLinks,
        },
        context: {
          clientName: clientNameService.clientName,
        },
      });

      return response?.data?.createCheckout ?? null;
    } catch (error) {
      errorsHandler(error);
      return null;
    }
  };

  return {
    createCheckout,
  };
};
