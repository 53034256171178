import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button } from '../button';
import { UpdateLocationModal } from '../update-location-modal';
import { LocationIcon } from '../icons/Icons';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '28px',
    maxWidth: '110px',
    fontSize: '9px',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '130px',
      height: '30px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '170px',
      height: '35px',
      fontSize: '12px',
    },
  },
}));

export const UpdateLocationButton = ({ isAttentionBar = false, className, onClick }) => {
  const classes = useStyles();
  const [isOpen, setOpening] = useState(false);

  const handleOpen = useCallback(() => {
    onClick?.();
    setOpening(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpening(false);
  }, []);

  const trigger = isAttentionBar ? (
    // ID need to Google Analytics, task CEP-2317
    <Button
      id="subnav-updatelocation" // ID need to Google Analytics, task CEP-2317
      size="action"
      color="white"
      className={clsx(classes.button, className)}
      variant="contained"
      onClick={handleOpen}
    >
      Add Zip Code
    </Button>
  ) : (
    <div
      id="subnav-updatelocation"
      className="flex cursor-pointer flex-col items-center gap-2 font-semibold text-black lg:flex-row lg:text-gray-light"
      onClick={handleOpen}
    >
      <LocationIcon className="h-4 w-4" />
      <div>Change location</div>
    </div>
  );

  return (
    <>
      {trigger}

      <UpdateLocationModal isOpen={isOpen} handleCancel={handleClose} />
    </>
  );
};
