import { useQuery } from '@apollo/client';
import { ITEM_BY_SLUG } from '../queries';
import { clientNameService } from '../services';

export const useProductQuery = (itemSlug) => {
  const {
    data: { items: { nodes = [] } = {} } = {},
    loading,
    error,
  } = useQuery(ITEM_BY_SLUG, {
    variables: { itemSlug },
    context: {
      clientName: clientNameService.clientName,
    },
  });

  if (error) {
    console.error(error);
  }

  return {
    product: nodes.length ? nodes[0] : {},
    isLoading: loading,
    isError: error,
  };
};
