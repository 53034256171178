import { gql } from '@apollo/client';
import { FRAGMENTS_QUERIES } from '../constants';

export const ORDERS = gql`
  query listOrders(
    $pagination: PaginationInput
    $filter: OrdersFilterInput
    $sort: OrdersSortEnum = createdAtDesc
  ) {
    orders(pagination: $pagination, filter: $filter, sort: $sort) {
      nodes {
        uid
        status
        number
        createdAt
        provider
        buyer {
          uid
          contact {
            formalName
            email
          }
        }
        seller {
          ${FRAGMENTS_QUERIES.address}
          ${FRAGMENTS_QUERIES.contact}
        }
        orderTotals {
          total
        }
      }
      ${FRAGMENTS_QUERIES.pagination}
    }
  }
`;
