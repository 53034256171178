import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce, getStoreFrontSlug } from '../../utils';
import { useQueryString } from '../../hooks';
import { SearchField } from '../search-field';

export const SearchPanel = () => {
  const textInput = useRef(null);
  const navigate = useNavigate(); // useNavigate replaces useHistory
  const { search, pathname } = useLocation();
  const params = useQueryString(search);
  const [searchValue, setSearchValue] = useState(params?.query ?? '');
  const storeFrontSlug = getStoreFrontSlug();

  const handleSearch = (value) => {
    setSearchValue(value);

    const query = value ? `query=${value}` : '';

    if (value) {
      if (pathname.includes(`/${storeFrontSlug}/search`)) {
        navigate({
          search: query,
        });
      } else {
        navigate({
          pathname: `/${storeFrontSlug}/search`,
          search: query,
        });
      }
    }
  };

  const clearSearchValue = () => {
    if (textInput?.current) {
      textInput.current.value = '';
    }
  };

  useEffect(() => {
    if (pathname !== `/${storeFrontSlug}/search`) {
      clearSearchValue();
    }
  }, [pathname]);

  return (
    <SearchField
      id="subnav-search" // ID needed for Google Analytics, task CEP-2317
      inputRef={textInput}
      defaultValue={searchValue}
      onChange={debounce(({ target }) => handleSearch(target?.value))}
    />
  );
};
