import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { clientNameService, emailService, sessionCacheStorage } from '../services';
import { ACTIONS_TYPES, ROUTING_CONFIG } from '../constants';
import { Context } from '../store';
import { getLocalStoreFrontSlug } from '../utils';

export const useLogout = () => {
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const removeSession = async () => {
    try {
      sessionCacheStorage.removeToken();
      clientNameService.removeClientName();
      dispatch({ type: ACTIONS_TYPES.CLEAR_LOCAL_CART });
      emailService.remove();
      dispatch({ type: ACTIONS_TYPES.CLEAR_ACCOUNT });
      navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
      window.scrollTo(0, 0);
      window.location.reload();
      return null;
    } catch (error) {
      console.error(error.graphQLErrors);
      return null;
    }
  };

  return {
    removeSession,
  };
};
