import React, { useContext, useEffect } from 'react';
import { useBrands } from '../hooks';
import { Context } from '../store';
import { clientNameService, sessionCacheStorage } from '../services';
import { getStateByZipCode, getBrandListPageForGoogleAnalytics } from '../utils';
import {
  Spinner,
  Breadcrumbs,
  EmptyState,
  ErrorState,
  BrandsCarouselWidget,
  InfiniteScrollGrid,
  SingleBrand,
} from '../components';

export const BrandsPage = () => {
  const { state } = useContext(Context);
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { preferredBrands, brandsTopSelling, brands, isLoading, isError, fetchListMore, hasMore } =
    useBrands({
      ...(zipCode && !clientNameService.isWholesaleClient
        ? { filter: { uidOmitsBrandRestrictionsIn: [zipCode, stateCode?.shortName] } }
        : {}),
    });

  useEffect(() => {
    if (!isLoading && !isError) {
      getBrandListPageForGoogleAnalytics(window, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  if (isLoading) return <Spinner />;
  const gridConfig = {
    xs: 6,
    sm: 3,
    md: 2,
  };

  return (
    <>
      <Breadcrumbs catalogName="Brands" />

      <h1 className="page-title">Brands</h1>

      {isError ? (
        <ErrorState />
      ) : (
        <>
          {preferredBrands?.length ? (
            <BrandsCarouselWidget
              style={{ marginBottom: '50px', marginTop: '50px' }}
              items={preferredBrands}
              title="My Favorite Brands"
              type="brands"
              classNameEntity="brand-icon" // classNameEntity need to Google Analytics, task CEP-2317
            />
          ) : brandsTopSelling?.length ? (
            <BrandsCarouselWidget
              style={{ marginBottom: '50px' }}
              items={brandsTopSelling}
              title="Top Selling Brands"
              type="brands"
              classNameEntity="brand-icon" // classNameEntity need to Google Analytics, task CEP-2317
            />
          ) : (
            <></>
          )}

          {!brands.length ? (
            <EmptyState />
          ) : (
            <InfiniteScrollGrid
              itemComponent={SingleBrand}
              customTitle={<h2 className="text-lg font-bold text-gray-dark">All Brands</h2>}
              breadcrumbs={false}
              data={brands}
              isLoading={isLoading}
              isError={isError}
              fetchListMore={fetchListMore}
              hasMore={hasMore}
              gridConfig={gridConfig}
              itemSpacing={1}
            />
          )}
        </>
      )}
    </>
  );
};
