import { useQuery } from '@apollo/client';
import { SCHEDULER_CONFIG } from '../queries';
import { clientNameService } from '../services';

export const useGetSchedulerConfig = () => {
  const { loading, error, data, refetch } = useQuery(SCHEDULER_CONFIG, {
    context: {
      clientName: clientNameService.clientName,
    },
  });

  return { loading, error, data, refetch };
};
