import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import BrandNoImage from '../../assets/brand-no-image.png';
import { getLocalStoreFrontSlug } from '../../utils';

export const CartEntity = ({ data, className, type, ...rest }) => {
  const storeFrontSlug = getLocalStoreFrontSlug();

  return (
    <div className={clsx('text-center', className)} {...rest}>
      <Link
        to={`/${storeFrontSlug}/${type}/${data.slug}`}
        className="flex h-full w-full transform flex-col items-center p-5 transition-transform duration-150 hover:scale-105"
      >
        <div
          className={clsx('relative w-full transition-transform duration-150', 'hover:scale-102')}
        >
          <img
            className="hover:scale-102 object-centertransition-transform h-full w-full duration-150"
            src={data?.coverImage?.mediumUrl ?? BrandNoImage}
            alt={data?.name || ''}
          />
        </div>
      </Link>
    </div>
  );
};
