import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { LogoutModal } from '../logout-modal';
import { useLogout } from '../../hooks';

export const LogoutButton = ({ onClick }) => {
  const [isOpen, setOpening] = useState(false);
  const { loading, removeSession } = useLogout();

  const handleOpen = () => {
    setOpening(true);
    onClick?.();
  };

  const handleClose = () => {
    setOpening(false);
  };
  const handleLogout = async () => {
    await removeSession();
    setOpening(false);
  };

  return (
    <Box>
      <div
        onClick={handleOpen}
        className="cursor-pointer text-base font-semibold uppercase text-black lg:text-xs lg:normal-case"
      >
        <span>Log out</span>
      </div>
      <LogoutModal
        loading={loading}
        isOpen={isOpen}
        handleCancel={handleClose}
        handleLogout={handleLogout}
      />
    </Box>
  );
};
