import { useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { setLocalStorageBasket } from '../utils';
import { clientNameService } from '../services';

export const ME_SHOPPING_CART = gql`
  query shoppingCart {
    shoppingCart {
      nodes {
        qty
        item {
          brand {
            name
            slug
            uid
          }
          categories {
            name
            slug
            uid
          }
          description
          primaryImage {
            url
            mediumUrl
            thumbUrl
          }
          name
          prices {
            currencyCode
            currencySign
            type
            value
          }
          slug
          uid
          sku
          availability {
            availableQty
            availabilityControl
            outOfStockThreshold
            lowInStockThreshold
          }
        }
      }
    }
  }
`;

const UPDATE_SHOPPING_CART = gql`
  mutation updateShoppingCart($cartItems: [CartItemInput!]!) {
    updateShoppingCart(cartItems: $cartItems) {
      cartItems {
        itemUid
        qty
      }
    }
  }
`;

const REMOVE_SHOPPING_CART = gql`
  mutation clearShoppingCart {
    clearShoppingCart {
      status
    }
  }
`;

export const useMeShoppingCart = () => {
  const [runQuery, { error }] = useLazyQuery(ME_SHOPPING_CART, {
    skip: true,
    context: {
      clientName: clientNameService.clientName,
    },
  });
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateShoppingCartMutation] = useMutation(UPDATE_SHOPPING_CART);
  const [removeShoppingCartMutation] = useMutation(REMOVE_SHOPPING_CART);

  const fetchMeShoppingCart = async () => {
    try {
      setLoading(true);
      const response = await runQuery();
      const data = response?.data?.shoppingCart?.nodes;

      if (data) {
        const newCart = data?.map(({ item, qty }) => ({
          data: item,
          amount: qty,
          uid: item?.uid,
        }));

        setLocalStorageBasket(newCart);

        setData(data);
        setLoading(false);

        return newCart;
      }
    } catch (error) {
      console.error('Fetch shopping cart failed: ', error);
      setLoading(false);
    }
  };

  const updateMeShoppingCart = async (cartItems) => {
    try {
      const response = await updateShoppingCartMutation({
        variables: { cartItems },
        context: {
          clientName: clientNameService.clientName,
        },
      });

      return response?.data?.cartItems;
    } catch (error) {
      console.error('Update shopping cart failed: ', error);
      return false;
    }
  };

  const removeMeShoppingCart = async () => {
    try {
      const response = await removeShoppingCartMutation({
        context: {
          clientName: clientNameService.clientName,
        },
      });

      return response?.data?.status;
    } catch (error) {
      console.error('Remove shopping cart failed: ', error);
      return false;
    }
  };

  return {
    shoppingCart: data,
    isLoading: loading,
    isError: error,
    fetchMeShoppingCart,
    updateMeShoppingCart,
    removeMeShoppingCart,
  };
};
