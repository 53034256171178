import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount(
    $contactInput: UpdateContactInput
    $configInput: AccountConfigInput
    $addressInput: AddressInput
  ) {
    updateAccount(
      contactInput: $contactInput
      configInput: $configInput
      addressInput: $addressInput
    ) {
      addresses {
        city
        countryCode
        postalCode
        stateCode
        streetLine1
        streetLine2
        type
        uid
      }
      config {
        orderNotificationsType
      }
      contact {
        email
        formalName
        phone
      }
      identifier
      uid
    }
  }
`;
