import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrors } from './useErrors';
import { useAvailabilityItems } from './useAvailabilityItems';
import { useComparingItemsByAvailability } from './useComparingItemsByAvailability';
import { Context } from '../store';
import { storageService } from '../services';
import { ACTIONS_TYPES, LOCAL_STORAGE_KEYS } from '../constants';
import { getLocalStoreFrontSlug } from '../utils';

export const useReorderItems = () => {
  const { errorsHandler } = useErrors();
  const { getItemsAvailability } = useAvailabilityItems();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);
  const { comparingItemsByAvailability } = useComparingItemsByAvailability();
  const [loading, setLoading] = useState(false);

  const reOrder = async (items) => {
    try {
      setLoading(true);
      const itemsIds = items?.reduce((acc, { item }) => {
        if (!acc.includes(item.uid)) {
          return [...acc, item.uid];
        }
        return acc;
      }, []);
      const availabilityItems = await getItemsAvailability(itemsIds);
      const newBasket = items?.reduce((acc, { item, qty }) => {
        const foundItem = acc?.find(({ uid }) => uid === item.uid);
        if (foundItem) {
          return acc.map((currentItem) => {
            if (currentItem?.uid === item.uid) {
              return {
                ...currentItem,
                amount: foundItem?.amount ? foundItem?.amount + qty : qty,
              };
            }

            return currentItem;
          });
        }

        return [
          ...acc,
          {
            amount: qty,
            data: item,
            uid: item?.uid,
          },
        ];
      }, []);
      const { isError, newCart } = await comparingItemsByAvailability(availabilityItems, newBasket);

      dispatch({ type: ACTIONS_TYPES.UPDATE_LOCAL_CART, payload: newCart });

      if (isError) {
        storageService.setItem(LOCAL_STORAGE_KEYS.isError, true);
      }

      navigate(`/${storeFrontSlug}/cart`);
      setLoading(false);
    } catch (error) {
      errorsHandler(error);
      console.error('Reorder failed: ', error);
      setLoading(false);
    }
  };

  return {
    loading,
    reOrder,
  };
};
