import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';

import { OrderNumber } from '../order-number';
import { OrderDate } from '../order-date';
import { Seller } from '../seller';
import { StatusLabel } from '../status-label';
import { OrderTotal } from '../order-total';
import { convertSnakeToCamel } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.gray.light}`,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(232,232,232,0.3)',
    },
  },
  title: {
    fontSize: '10px',
    color: theme.palette.gray.main,
    textTransform: 'uppercase',
  },
  seller: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  right: {
    textAlign: 'right',
  },
}));

export const MobileOrderItem = ({ data, onRowClick, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box
      className={classes.container}
      onClick={(e) => {
        onRowClick?.({ e, navigate, data, ...rest });
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box className={classes.title}>Order #</Box>
          <OrderNumber orderNumber={data?.number} />
        </Box>

        <Box className={classes.right}>
          <Box className={classes.title}>Created</Box>
          <OrderDate date={data?.createdAt} />
        </Box>
      </Box>

      <Box className={classes.seller}>
        <Seller seller={data?.seller} />
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <StatusLabel status={convertSnakeToCamel(data?.status)} />

        <Box className={classes.right}>
          <Box className={classes.title}>Amount</Box>
          <OrderTotal order={data} />
        </Box>
      </Box>
    </Box>
  );
};
