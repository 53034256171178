import React, { useState, useEffect, useRef } from 'react';
import { ChevronRightIcon } from '../icons/Icons';
import { LogoutButton } from '../logout-button';
import clsx from 'clsx';

export const Popover = ({ account, children, navigateToUserDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev); // Toggle popover visibility
  };

  const handleClose = () => {
    setIsOpen(false); // Close the popover
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsOpen(false); // Close popover when clicking outside
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-left" ref={popoverRef}>
      <div onClick={handleToggle}>{children}</div>

      <div
        className={clsx(
          'absolute right-0 z-10 rounded-md bg-white p-3 shadow-lg ring-1 ring-black ring-opacity-5',
          !isOpen && 'hidden',
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
        onClick={handleClose}
      >
        <div
          className="flex cursor-pointer items-center justify-between"
          onClick={navigateToUserDetails}
        >
          <div>
            <div className="font-semibold text-primary">{account?.contact.formalName}</div>
            <div className="text-xs">{account?.contact?.email}</div>
          </div>
          <div className="ml-6">
            <ChevronRightIcon className="w-6 text-primary" />
          </div>
        </div>
        <div className="mt-2 border-t pt-2">
          <LogoutButton />
        </div>
      </div>
    </div>
  );
};
