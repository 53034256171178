import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { clientNameService, slugService } from '../services';
import { getStoreFrontSlug } from '../utils';
import { CONFIG_QUERY } from '../queries';

export const useConfigQuery = () => {
  const storeFrontSlug = getStoreFrontSlug();
  const { refetch } = useQuery(CONFIG_QUERY, {
    skip: true,
    context: {
      clientName: clientNameService.clientName,
    },
  });
  const [storeFront, setStoreFrontData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sellerName, setSellerName] = useState('');
  const [error, setError] = useState(null);
  const isMounted = useRef(true);

  const fetchConfig = async () => {
    try {
      setLoading(true);
      const response = await refetch();
      const storefrontData = response?.data?.current?.config?.storefront;

      const formalName = response?.data?.current?.config?.seller?.contact?.formalName;

      if (formalName && isMounted.current) {
        setSellerName(formalName);
      }

      if (storefrontData && isMounted.current) {
        setStoreFrontData(storefrontData);

        setLoading(false);
      }
    } catch (error) {
      console.error('Fetch config failed: ', error);
      setLoading(false);
      setError(error);
      slugService.remove();
    }
  };

  useEffect(() => {
    if (storeFrontSlug) {
      fetchConfig();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    config: storeFront,
    sellerName,
    isLoading: loading,
    isError: error,
  };
};
