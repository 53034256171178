import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useErrors } from './useErrors';
import { clientNameService } from '../services';
import { getStoreFrontUrl } from '../utils';

export const CREATE_ACCOUNT = gql`
  mutation createAccount($landingUrl: String!, $input: ContactInput!) {
    registerAccount(landingUrl: $landingUrl, contactInput: $input) {
      identifier
      uid
    }
  }
`;

export const useCreateAccount = () => {
  const [createAccountMutation] = useMutation(CREATE_ACCOUNT);
  const { errorsHandler } = useErrors();
  const [loading, setLoading] = useState(false);

  const createAccount = async ({ formalName, email }) => {
    try {
      setLoading(true);
      const response = await createAccountMutation({
        variables: {
          landingUrl: getStoreFrontUrl(window),
          input: {
            formalName,
            email,
          },
        },
        context: {
          clientName: clientNameService.clientName,
        },
      });

      setLoading(false);

      return response?.data?.registerAccount;
    } catch (error) {
      errorsHandler(error);
      setLoading(false);
    }
  };

  return {
    loading,
    createAccount,
  };
};
