import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderSuccessImg from '../assets/success-order.svg';
import { Context } from '../store';
import { ACTIONS_TYPES } from '../constants';
import { OrderStatusLayout } from '../components';
import {
  getLocalStorageBasket,
  getLocalStoreFrontSlug,
  handlePurchaseForGoogleAnalytics,
  getOrderSuccessfulPageForGoogleAnalytics,
} from '../utils';

export const OrderSuccessfulPage = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Context);
  const storeFrontSlug = getLocalStoreFrontSlug();

  useEffect(() => {
    const basket = getLocalStorageBasket();

    if (basket.length) {
      handlePurchaseForGoogleAnalytics(window, basket);
      getOrderSuccessfulPageForGoogleAnalytics(window);

      dispatch({ type: ACTIONS_TYPES.CLEAR_LOCAL_CART });
    }
  }, [dispatch, state.cart]);

  return (
    <div className="purchase-page">
      <OrderStatusLayout
        title="Thank you for your order"
        subTitle="Your order has been successfully placed"
        image={OrderSuccessImg}
        imageAlt="Order Successful"
        buttonText="To the Home Page"
        onClickButton={() => navigate(`/${storeFrontSlug}`)}
      />
    </div>
  );
};
