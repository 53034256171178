import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Divider, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { InputTextField } from '../input-text-field';
import { formSchema } from './form-schema';
import { authViewStates } from '../../constants/auth-view-states';
import { Button } from '../button';
import { AuthFormFooter } from '../auth-form-footer';
import { ConsoleLink } from '../footer/console-link';
import { Context } from '../../store';

const useStyles = makeStyles(() => ({
  formTitle: {
    margin: '0 0 14px',
    color: 'var(--dark-gray)',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
  },
  formSubTitle: {
    margin: '14px 0 36px',
    color: 'var(--gray)',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
  },
  input: {
    width: '100%',
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  form: {
    width: '100%',
  },
  asterisk: {
    color: 'red',
  },
  divider: {
    marginTop: '20px',
  },
  checkboxText: {
    textAlign: 'left',
    fontSize: '10px',
    margin: '0 0 16px 16px',
  },
  label: {
    fontSize: '12px',
  },
}));

export const SignInForm = ({
  email,
  changeState,
  handleLoginAccount,
  isSFOUserType,
  setIsSFOUserType,
}) => {
  const { state } = useContext(Context);
  const { config } = state || {};
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: email ?? '',
    },
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    handleLoginAccount(data);
  };

  const handleUserTypeChange = (checked) => {
    setIsSFOUserType(checked);
  };

  return (
    <Grid container justifyContent="center">
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
        <Grid container spacing={1} justifyContent="center" align="center">
          <Grid item xs={12}>
            <p className={classes.formTitle}>Welcome</p>
            <p className={classes.formSubTitle}>Please Log In to your Account</p>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputTextField
                      fullWidth
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      label="Email"
                      InputLabelProps={{
                        required: true,
                        classes: {
                          asterisk: classes.asterisk,
                        },
                      }}
                      inputProps={{ className: classes.input }}
                      {...field}
                    />
                  )}
                />
              </Grid>

              {config.wholesale.enabled && (
                <Grid item xs={12} className={classes.checkboxText}>
                  <Controller
                    name="isSFO"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            color="primary"
                            checked={isSFOUserType}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              field.onChange(checked);
                              handleUserTypeChange(checked);
                            }}
                          />
                        }
                        label={<div className={classes.label}>Login as a SalonHQ Pro?</div>}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>

            <Button type="submit" variant="contained" disabled={!isValid}>
              Send magic link
            </Button>

            <AuthFormFooter
              description="Don't have an account?"
              labelButton="Sign Up"
              onChangeState={() => changeState(authViewStates.signUp)}
            />

            <Divider className={classes.divider} />
            <AuthFormFooter
              description="Do you want to Manage your Storefront?"
              labelButton={<ConsoleLink description="Click Here" />}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
