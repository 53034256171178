import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import {
  useOrderDetails,
  useOrderItems,
  useReorderItems,
  useRequestReturnItems,
} from '../../hooks';
import {
  BuyerDetails,
  Breadcrumbs,
  SellerDetails,
  Timeline,
  OrderCartSubtotal,
  Spinner,
  RequestReturnButton,
} from '../../components';
import { getAccountOrderDetailsPageForGoogleAnalytics } from '../../utils';
import { OrderFinances } from '../../components/order-finances';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '29px',
    fontWeight: 'bold',
    color: theme.palette.gray.dark,
    textAlign: 'center',
    '& span': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(8),
      fontSize: '25px',
    },
  },
  head: {
    alignItems: 'center',
    marginBottom: '50px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '48px',
      '& > div:last-child': {
        marginTop: theme.spacing(8),
      },
    },
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: theme.palette.gray.dark,
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
    '& span': {
      verticalAlign: 'text-bottom',
      paddingLeft: '6px',
      [theme.breakpoints.down('md')]: {
        verticalAlign: 'middle',
      },
    },
  },
  timelineWrapper: {
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  timelineHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  returnButton: {},
  timeline: {
    paddingTop: '35px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingLeft: 0,
    },
  },
  trackingNumber: {
    paddingTop: '40px',
    marginBottom: '70px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '40px',
    },
  },
  subTotal: {
    marginTop: '15px',
    [theme.breakpoints.down('md')]: {
      marginTop: '25px',
      marginBottom: '20px',
    },
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.gray.light,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '60px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
    },
  },
  created: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  createdLabel: {
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '15px',
    color: theme.palette.gray.dark,
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      marginRight: '0',
      lineHeight: '14px',
    },
  },
  createdValue: {
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.palette.gray.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '16px',
    },
  },
  tabs: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  activeTab: {
    color: theme.palette.primary.main,
  },
}));

const TABS = [
  {
    value: 'timeline',
    title: 'Timeline',
  },
  {
    value: 'finances',
    title: 'Finances',
  },
];

export const OrderDetailsPage = () => {
  const [currentTab, setCurrentTab] = useState(TABS[0].value);
  const classes = useStyles();
  const { orderId } = useParams();
  const {
    loading: loadingOrderDetails,
    error: errorOrderDetails,
    order,
    paymentDetails,
  } = useOrderDetails({ orderId });

  const { orderItems } = useOrderItems(order?.orderItems);
  const { loading: loadingReorderItems, reOrder } = useReorderItems();
  const { orderItems: returnableOrderItems } = useRequestReturnItems(orderItems);

  useEffect(() => {
    if (!loadingOrderDetails && !errorOrderDetails) {
      getAccountOrderDetailsPageForGoogleAnalytics(window);
    }
  }, [loadingOrderDetails]);

  if (loadingOrderDetails) {
    return <Spinner isBackground />;
  }

  const handleChangeTab = (_event, newValue) => setCurrentTab(newValue);

  return (
    <Box container>
      {loadingReorderItems && <Spinner isBackground fixed />}

      <Breadcrumbs
        links={[
          {
            link: '/account-details/order-history',
            linkName: 'Order History',
          },
        ]}
        catalogName={`Order #${order?.number}`}
      />

      <Box>
        <h1 className={classes.title}>
          Order <span>#{order?.number}</span>
        </h1>

        <Grid className={classes.head} container>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Box>
              <Box mb={8}>
                <BuyerDetails data={order?.buyer} />
              </Box>

              {order?.seller && <SellerDetails data={order?.seller} />}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <OrderCartSubtotal order={order} onClick={reOrder} />
          </Grid>
        </Grid>

        <Box className={classes.divider} />
        <Box>
          <Tabs value={currentTab} onChange={handleChangeTab} className={classes.tabs}>
            {TABS.map((tab) => (
              <Tab
                label={tab.title}
                value={tab.value}
                key={tab.value}
                classes={{ selected: classes.activeTab }}
              />
            ))}
          </Tabs>
        </Box>

        {currentTab === TABS[0].value && (
          <Box className={classes.timelineWrapper}>
            <Box className={classes.timeline}>
              <Timeline activeStatus={order?.status} orderItems={orderItems} />
            </Box>

            <Box className={classes.buttonWrapper}>
              <RequestReturnButton
                disabled={!returnableOrderItems.length || order?.provider === 'external'}
              />
            </Box>
          </Box>
        )}
        {currentTab === TABS[1].value && (
          <OrderFinances
            orderTotals={paymentDetails?.orderTotals}
            paymentDetails={paymentDetails?.paymentDetails}
          />
        )}
      </Box>
    </Box>
  );
};
