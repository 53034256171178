import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { clientNameService, emailService, sessionCacheStorage } from '../services';
import { ACTIONS_TYPES, CLIENT_NAME, ROUTING_CONFIG } from '../constants';
import { useErrors } from './useErrors';
import { getLocalStoreFrontSlug } from '../utils';
import { useContext } from 'react';
import { Context } from '../store';

const CREATE_SESSION_WITH_PASS_CODE = gql`
  mutation createSessionWithOneTimeCode($identifier: String!, $password: String!) {
    createSessionWithOneTimeCode(identifier: $identifier, password: $password) {
      token
    }
  }
`;

export const useCreateSessionWithOneTimeCode = () => {
  const [createSessionMutation, { data, loading }] = useMutation(CREATE_SESSION_WITH_PASS_CODE);
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);
  const { errorsHandler } = useErrors();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const createSession = async (identifier, password, isSFOUserType) => {
    try {
      const response = await createSessionMutation({
        variables: { identifier, password },
        context: {
          clientName: clientNameService.loginClientName(isSFOUserType),
        },
      });

      const newToken = response?.data?.createSessionWithOneTimeCode?.token;

      if (newToken) {
        sessionCacheStorage.setToken(newToken);
        emailService.remove();

        if (isSFOUserType) {
          clientNameService.clientName = CLIENT_NAME.wholesale;
        }

        if (clientNameService.isWholesaleClient) {
          dispatch({ type: ACTIONS_TYPES.CLEAR_LOCAL_CART });
        }

        navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
        window.location.reload();
        return newToken;
      }
    } catch (error) {
      errorsHandler(error);
      sessionCacheStorage.removeToken();
      clientNameService.removeClientName();
      navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
    }
  };

  return {
    createSession,
    isLoading: loading,
    ...data,
  };
};
