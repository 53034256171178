import React from 'react';
import { useCategoriesQuery } from '../../hooks';
import clsx from 'clsx';
import { getLocalStoreFrontSlug } from '../../utils';
import { useNavigate } from 'react-router-dom';

export const AppBarCategories = () => {
  const { categories, isLoading, isError } = useCategoriesQuery();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const navigate = useNavigate();

  if (isError || isLoading) return <div></div>;

  const handleCategoryClick = (slug) => {
    navigate(`/${storeFrontSlug}/categories/${slug}`);
  };

  return (
    <div className="scrollbar-hide flex max-w-full overflow-x-auto whitespace-nowrap">
      {categories.map((item, index) => (
        <div
          className={clsx(
            'relative mr-2 min-w-fit cursor-pointer px-4 py-2 text-center text-xs font-semibold text-black',
            index !== 0 &&
              'before:absolute before:left-[-8px] before:top-1/2 before:h-4 before:-translate-y-1/2 before:border-l before:border-gray-300',
          )}
          key={item.uid}
          onClick={() => handleCategoryClick(item.slug)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};
