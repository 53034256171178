import React from 'react';
import { SearchPanel } from '../search-panel';
import { AppBarCategories } from './app-bar-categories';
import { Drawer } from './drawer';
import { MobileHeader } from './mobile-header';

export const Mobile = ({ navRoutes }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <>
      <div className="shadow-lg lg:hidden">
        <MobileHeader toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
        <div className="flex h-12 items-center justify-center border-b border-t border-gray-light px-4">
          <SearchPanel />
        </div>
        <div className="flex h-10 items-center">
          <AppBarCategories />
        </div>
      </div>
      <Drawer isMenuOpen={isMenuOpen} navRoutes={navRoutes} toggleMenu={toggleMenu} />
    </>
  );
};
