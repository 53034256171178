import { gql, useLazyQuery } from '@apollo/client';
import { useShowToast } from './useShowToast';
import { clientNameService } from '../services';

export const VERIFY_COUPON_CODE = gql`
  query verifyCouponCode($code: String!) {
    verifyCouponCode(code: $code) {
      nodes {
        uid
        code
        status
        percent
        activeFrom
        activeTo
      }
    }
  }
`;

export const useVerifyCouponCode = () => {
  const [runQuery, { data, error, loading }] = useLazyQuery(VERIFY_COUPON_CODE, {
    context: {
      clientName: clientNameService.clientName,
    },
  });
  const { showError } = useShowToast();
  const runVerifyCouponCodeQuery = async (code) => {
    const response = await runQuery({ variables: { code } });
    if (code && response?.error) {
      showError(response?.error?.message);
    }
  };

  return {
    runVerifyCouponCodeQuery,
    verifyCouponCodeLoading: loading,
    couponCodeUid: data?.verifyCouponCode?.nodes[0]?.uid,
    couponCode: data?.verifyCouponCode?.nodes[0]?.code,
    couponCodeError: error,
  };
};
