import React from 'react';
import { Box } from '@material-ui/core';

import { Spinner } from '../spinner';
import { Breadcrumbs } from '../breadcrumbs';
import { EmptyState } from '../empty-state';
import { ErrorState } from '../error-state';
import { InfiniteScroll } from '../infinite-scroll';

export const InfiniteScrollGrid = ({
  itemComponent: ItemComponent,
  title,
  isLoading,
  isError,
  data,
  fetchListMore,
  hasMore,
  breadcrumbs = true,
  customTitle,
  subTitle,
  gridConfig,
  itemSpacing,
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {breadcrumbs && <Breadcrumbs catalogName={title} />}

      {customTitle || <h1 className="page-title">{title}</h1>}

      {isError ? (
        <ErrorState />
      ) : (
        <>
          {!data.length ? (
            <EmptyState />
          ) : (
            <Box mt={6} mb={6}>
              {subTitle}
              <InfiniteScroll
                itemComponent={ItemComponent}
                data={data}
                fetchListMore={fetchListMore}
                hasMore={hasMore}
                gridConfig={gridConfig}
                itemSpacing={itemSpacing}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};
