import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useCreateSessionWithToken, useQueryString } from '../../hooks';
import { clientNameService } from '../../services';

export const SessionPage = () => {
  const { search } = useLocation();
  const params = useQueryString(search);
  const { storeFrontSlug, clientName } = useParams();
  const { createSession } = useCreateSessionWithToken();

  useEffect(() => {
    if (params?.token) {
      clientNameService.clientName = clientName;
      createSession(params?.token, storeFrontSlug, params?.type, params?.resource);
    }
  }, []);

  return null;
};
