import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from '../../constants';
import { OrderStatusLayout } from '../../components';
import OrderReturnSuccessfulImg from '../../assets/order-return-successful.svg';
import { getLocalStoreFrontSlug, getOrderReturnSuccessPageForGoogleAnalytics } from '../../utils';

export const OrderReturnSuccessfulPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const goToOrderDetails = () => {
    const url = ROUTING_CONFIG.orderHistoryDetails
      .replace(':storeFrontSlug', storeFrontSlug)
      .replace(':orderId', orderId);
    navigate(url);
  };

  useEffect(() => {
    getOrderReturnSuccessPageForGoogleAnalytics(window);
  });

  return (
    <OrderStatusLayout
      title="Return Successful "
      subTitle="Your Return Has Been Successfully Requested"
      image={OrderReturnSuccessfulImg}
      imageAlt="Return Successful"
      buttonText="Order Details"
      onClickButton={() => goToOrderDetails()}
    />
  );
};
